// import {
// 	hasClass,
// 	addClass,
// 	removeClass,
// } from './helper/helper.js';

export function annualSalesMeter() {
	if (document.querySelector('.m-form--check')) {
		var umsatzUnterEins = document.getElementById('umsatzUnterEins');
		var umsatzUeberEins = document.getElementById('umsatzUeberEins');
		var annualSalesMeter = document.getElementById('m-form--check__visual');

		umsatzUnterEins.addEventListener('change', function() {
			annualSalesMeter.className = 'm-form__visual m-form--check__visual m-form--check__visual---umsatzUnterEins';
		});

		umsatzUeberEins.addEventListener('change', function() {
			annualSalesMeter.className = 'm-form__visual m-form--check__visual m-form--check__visual---umsatzUeberEins';
		});
	}
}