import { htmlDoc } from './helper/vars.js';

import {
	hasClass
} from './helper/helper.js';

export function addJsToHtml() {
	if (hasClass(htmlDoc, 'no-js')) {
		htmlDoc.className = "js";
	}
}